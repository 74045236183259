/**
 * Toggle list item (FAQ)
 * 
 */

const faqItemToggle = () => {
  const btns = document.querySelectorAll('.js-faq-tog-btn');

  if (btns) {
    btns.forEach(btn => {
      const parentLi = btn.closest('.js-faq-item');
      
      btn.addEventListener('click', () => {
        parentLi?.classList.toggle('active');
      })
    });
  }
}

export default faqItemToggle;
