import faqItemToggle from './components/faqItemToggle'
import backToTop from './components/toTop'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    faqItemToggle(),
    backToTop()
  },
  false
)
